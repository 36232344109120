import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/common.css'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import './assets/rem'
import {
  ConfigProvider,
  DropdownMenu,
  DropdownItem,
  Button,
  Col,
  Row,
  Rate,
  Field,
  CellGroup,
  Tag,
  IndexBar,
  IndexAnchor,
  Cell,
  Search,
  Image as VanImage,
  Empty,
  Collapse,
  CollapseItem,
  Checkbox,
  CheckboxGroup,
  Swipe,
  SwipeItem,
  List,
  Tab,
  Tabs,
  Popup,
  Pagination,
  Sidebar,
  SidebarItem,
  Icon,
  NavBar,
} from 'vant'
import 'vant/lib/index.css'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Button)
  .use(Col)
  .use(Row)
  .use(Rate)
  .use(Field)
  .use(CellGroup)
  .use(Tag)
  .use(IndexBar)
  .use(IndexAnchor)
  .use(Cell)
  .use(Search)
  .use(VanImage)
  .use(Empty)
  .use(Tab)
  .use(Tabs)
  .use(ConfigProvider)
  .use(Collapse)
  .use(CollapseItem)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Swipe)
  .use(SwipeItem)
  .use(List)
  .use(Popup)
  .use(Pagination)
  .use(Sidebar)
  .use(SidebarItem)
  .use(Icon)
  .use(NavBar)
  .use(ElementPlus, { locale: zhCn })
  .use(store)
  .use(router)
  // .component(ElButton.name, ElButton)
  // .component(ElSelect.name, ElSelect)
  .mount('#app')
