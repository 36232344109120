import { createRouter, createWebHashHistory } from "vue-router";
import { h, resolveComponent } from "vue";
const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "Index",
    meta: {
      title: "首页",
    },
    component: () => import("../views/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
